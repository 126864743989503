/* src/App.css */
.landing-page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.gradient-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(140deg, #ffb1b1 0%, #ffcd4b 50%, #ff89b1 100%);
  z-index: -2;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.circle {
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: float 15s infinite;
  opacity: 0;
}

.circle:nth-child(1) { width: 400px; height: 400px; left: 10%; top: 10%; animation-delay: 0s; }
.circle:nth-child(2) { width: 600px; height: 600px; left: 60%; top: 20%; animation-delay: 2s; }
.circle:nth-child(3) { width: 300px; height: 300px; left: 30%; top: 50%; animation-delay: 4s; }
.circle:nth-child(4) { width: 500px; height: 500px; left: 70%; top: 60%; animation-delay: 6s; }
.circle:nth-child(5) { width: 350px; height: 350px; left: 20%; top: 80%; animation-delay: 8s; }

@keyframes float {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(-100px) scale(1.1);
    opacity: 0;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  min-height: 100vh;
}

.featured-image {
  width: 300px;
  height: 400px;
  object-fit: contain;
  transition: opacity 0.5s ease;
  animation: fadeIn 0.5s ease;
}

.talk-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #9333ea, #c084fc);
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
  color: #fff;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 16px 32px;
  transition: transform .2s ease, box-shadow .2s ease;
  z-index: 1000;
}

.talk-button:hover {
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 6px 16px rgba(147, 51, 234, 0.4);
}

.talk-button:active {
  transform: translateX(-50%) translateY(0);
  box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}